import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import React, { Component, useState } from 'react';
import 'react-vertical-timeline-component/style.min.css';
import midArk from '../imgs/midArkVending.png';
import vehicleBuilds from '../imgs/vehicleBuilds.png';
import algoArt from '../imgs/algoArt.png';
import seventy1 from '../imgs/seventy1.png';
import seventy2 from '../imgs/seventy2.png';
import seventy3 from '../imgs/seventy3.png';
import algo1 from '../imgs/algo1.png';
import algo2 from '../imgs/algo2.png';
import algo3 from '../imgs/algo3.png';
import algo4 from '../imgs/algo4.png';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Seventy1Modal from './Seventy1Modal.js';
import AlgoArtModal from './AlgoArtModal.js';


class Timeline extends Component{
  constructor(props){
    super(props);
    this.state = {
      sevent1ModalShow:false,
      AlgoArtModalShow:false,
    }
}

  render(){

    return(
          <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2021"
          iconStyle={{ background: 'rgb(159,20,50)', color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">Seventy-1 Auto</h3>
          <h5 className="vertical-timeline-element-subtitle">Used Car Dealership Website</h5>
          <br></br>
          <Carousel>
              <Carousel.Item onClick={()=> this.setState({sevent1ModalShow:true})}>
                  <img src={seventy1} className="carouselImg" />
              </Carousel.Item>
              <Carousel.Item onClick={()=> this.setState({sevent1ModalShow:true})}>
                  <img src={seventy2}  className="carouselImg" />
              </Carousel.Item>
              <Carousel.Item onClick={()=> this.setState({sevent1ModalShow:true})}>
                  <img src={seventy3} className="carouselImg" />
              </Carousel.Item>
          </Carousel>
          <Seventy1Modal show={this.state.sevent1ModalShow} onHide={()=> this.setState({sevent1ModalShow:false}) }></Seventy1Modal>
          <hr></hr>
          <p>
            This is a website I built for a friends used car dealership to manage his inventory and display it online. It was built using React and Node for the backend. I built an API in Node to connect to the SQL server where I am storing the vehicle information and Firebase Authentication for the admin portal user system. 
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2021"
          iconStyle={{ background: 'rgb(159,20,50)', color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">AlgoArt</h3>
          <h5 className="vertical-timeline-element-subtitle">NFT Marketplace</h5>
          <Carousel>
              <Carousel.Item>
                  <img src={algo1} className="carouselImg" onClick={()=> this.setState({AlgoArtModalShow:true})}/>
              </Carousel.Item>
              <Carousel.Item>
                  <img src={algo2}  className="carouselImg"  onClick={()=> this.setState({AlgoArtModalShow:true})}/>
              </Carousel.Item>
              <Carousel.Item>
                  <img src={algo3}  className="carouselImg" onClick={()=> this.setState({AlgoArtModalShow:true})}/>
              </Carousel.Item>

          </Carousel>
          <AlgoArtModal show={this.state.AlgoArtModalShow} onHide={()=> this.setState({AlgoArtModalShow:false}) }></AlgoArtModal>
          <hr></hr>
          <p>
            This web app is a NFT Marketplace for the Algorand Network that I built for a blockchain hackathon during my senior year of college. My team and I went on to win 3rd place in the student track with this UI. I built this as my first project and as a opportunity  to learn React.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2016"
          iconStyle={{ background: 'rgb(159,20,50)', color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">Vehicle Builds</h3>
          <img src={vehicleBuilds}  className="carouselImg" />
          <hr></hr>
          <p>
          I built this website in high school for a way for automotive enthusiast to easily show off the modifications they have done to their vehicles on social media by putting a link to my website in the bio. I built this website using PHP, SQL, Bootstrap along with HTML and CSS. 
          </p>
          <br></br>
          <a href="http://maxgee.me/VEHICLEBUILDS/"><Button variant="primary" className="timeLineButton">View Me!</Button></a>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2015"
          iconStyle={{ background: 'rgb(159,20,50)', color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">Mid-Arkansas Vending</h3>
          <br></br>
          <img src={midArk}  className="carouselImg" />
          <hr></hr>
          <p>
          This is a website I built for my dad’s vending business in high school. It was one of the first website I built that is in use today. It is a basic website just using Bootstrap, HTML, and CSS.
          </p>
          <br></br>
          <a href="http://midarkansasvending.com/"><Button variant="primary" className="timeLineButton">View Me!</Button></a>
        </VerticalTimelineElement>
      </VerticalTimeline>
    );
  }
}
export default Timeline;
