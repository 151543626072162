import Contrainer from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const Navigation = () =>(
    <Navbar bg="dark" expand="lg" fixed="top" variant="dark">
      <Contrainer>
  <Navbar.Brand href="#hello">Max Gee</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="#about">About Me</Nav.Link>
      <Nav.Link href="#edu">Education</Nav.Link>
      <Nav.Link href="#port">Portfolio</Nav.Link>
      <Nav.Link href="#contact">Contact Me</Nav.Link>
    </Nav>
  </Navbar.Collapse>
  </Contrainer>
</Navbar>
  );

export default Navigation;
