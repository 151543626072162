import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import algo1 from '../imgs/algo1.png';
import algo2 from '../imgs/algo2.png';
import algo3 from '../imgs/algo3.png';
import algo4 from '../imgs/algo4.png';



const AlgoArtModal = (props) =>{
   return( <div>
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            AlgoArt
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="modalCarousel">
                <Carousel>
                    <Carousel.Item>
                        <img src={algo1}className="carouselImg"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={algo2} className="carouselImg"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={algo3} className="carouselImg"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={algo4} className="carouselImg"/>
                    </Carousel.Item>
                </Carousel>
          </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide} block>Close</Button>
        </Modal.Footer>
        </Modal>
    </div>
   )
}

export default AlgoArtModal;