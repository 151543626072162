import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import AllCars from '../imgs/seventyCACar.png';
import CarView from '../imgs/seventyCCar.png';
import ControlPannel from '../imgs/seventyCAPannel.png';
import UpateCar from '../imgs/seventyCUpdate.png';



const Seventy1Modal = (props) =>{
   return( <div>
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Seventy-1 Auto
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="modalCarousel">
                <Carousel>
                    <Carousel.Item>
                        <img src={AllCars}className="carouselImg"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={CarView} className="carouselImg"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={ControlPannel} className="carouselImg"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={UpateCar} className="carouselImg"/>
                    </Carousel.Item>
                </Carousel>
          </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide} block>Close</Button>
        </Modal.Footer>
        </Modal>
    </div>
   )
}

export default Seventy1Modal;