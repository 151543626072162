//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Contrainer from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
//Components
import Navigation from './components/Nav.js';
import Timeline from './components/Timeline.js'
import rzrBack from './imgs/rzrback.png';
import linkedin from './imgs/linkedin.svg';


function App() {
  return (
    <div>    
    <Navigation></Navigation>
    <br></br>
    <Contrainer id="hello">
          <Jumbotron className="bodyContainer">
          <h1>Hi, my name is Max!</h1>
            <h3>I'm a web developer located in Fayetteville, AR.</h3>
          </Jumbotron>
          <Jumbotron id="about">
              <h2>About Me</h2>
              <p>I have been interested in technology since I was a kid. I have always been the person people come to when they need help when it comes to technology. 
                I started learning to program in middle school making simple websites using just HTML and CSS. Later, at the beginning of high school I got bored
                making basic website that could only display information and wanted to make more advanced websites that could store information as well have a user system and profiles 
                so, I went to YouTube to learn how to accomplish this. After a lot of tutorials and problem solving, I was able to make my own user systems with authentication as well 
                as user profiles using PHP and SQL.</p>
                
                <p> Once I was in college, I was eager to learn how to make mobile applications for iOS or Android, so I took a 6–month bootcamp during 
                my freshman summer where I was taught JavaScript, Angular and Ionic and how to uses these languages and frameworks to build web and mobile applications. During the rest 
                of my college years, I took classes that covered building databases with SQL, how to make windows applications using C# and how to connect these applications to 
                MS SQL Server database, as well as the basics on how to make blockchain applications using Solidity on the Ethereum network. In my free time currently as well as 
                at the end of my college career I have been learning React and have already made a couple of small projects using it.
              
              </p>
          </Jumbotron>
          <Jumbotron id="edu">
            <h2>Education</h2>
            <Row>
              <Col lg="3">
              <img src={rzrBack} className="schoolLogo"/>
              
              </Col>
              <Col lg="9">
                <Card className="schoolCard">
                  <div className="schoolName"><Card.Title>University of Arkansas</Card.Title></div>
                  <div className="schoolSub"><Card.Subtitle>Walton School of Business</Card.Subtitle></div>
                  <div className="schoolDegree"><Card.Text>Major: Business Information Systems - Blockchain Enterprise Systems</Card.Text></div>
                  <div className="schoolDegree"><Card.Text>Minor: Real Estate</Card.Text></div>
                </Card>
                <br></br>
                <Card className="schoolCard">
                  <div className="schoolName"><Card.Title>University of Arkansas - Global Campus</Card.Title></div>
                  <div className="schoolSub"><Card.Subtitle>Mobile Development</Card.Subtitle></div>
                  <div className="schoolDegree"><Card.Text>6-month mobile development bootcamp.</Card.Text></div>
                </Card>
              </Col>
            </Row>
            <br></br>
            <h2>Achievements</h2>
            <Card className="schoolCard">
              <div className="schoolName"><Card.Title>2021 RZRBlock Hackathon</Card.Title></div>
              <div className="schoolSub"><Card.Subtitle>3rd Place Student Track</Card.Subtitle></div>
              <div className="schoolDegree"><Card.Text>My team and I placed 3rd place in a blockchain hackathon where we made a UI for a NFT Marketplace on the Algorand Network. 
              <br></br><a href="https://www.youtube.com/watch?v=zZTf01XcjmA">You can watch our presentation here!</a></Card.Text></div>
            </Card>
          </Jumbotron>
          <Jumbotron id="port">
            <h2>Portfolio</h2>
            <Timeline></Timeline>
          </Jumbotron>
          <Jumbotron id="contact" className="center">
            <a name="contact"></a>
            <h2>Contact Me</h2>
            <p>Reach out to me through email anytime at maxgee@me.com</p>
            <a href="http://www.linkedin.com/in/maxgee97"><img src={linkedin} className="linkedin"/></a>
          </Jumbotron>
    </Contrainer>
    </div>
  );
}

export default App;
